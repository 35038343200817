//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { mapState } from 'vuex'
  export default {
      computed: {
          ...mapState({
              model: ({ resourcesStore: {model} }) => model,
          }),
      },
      components: {
          Paywall: () => import('~/components/payment/module.vue')
      },
      methods: {
          close: function() {
              if (event.srcElement.className == 'modal-container' || event.srcElement.className == 'close_container' || event.srcElement.className == 'close' || event.srcElement.tagName == 'path') {
                  this.$store.commit('modalStore/setModal', false)
                  this.setCookie();
              }
          },
          setCookie: function() {
            let cname = 'black-friday-2024';
            let cvalue = true;
            let exdays = 1;
            this.storeCookie(cname, cvalue, exdays);
            this.$root.bfCookie = true;
        },
    }
}
